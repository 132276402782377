<template>
    <div>
        <el-table :data="tableData" height="600px" border style="width: 100%">
            <el-table-column prop="stu_id" label="学号" width="140">
            </el-table-column>
            <el-table-column prop="stu_name" label="姓名" width="120">
            </el-table-column>
            <el-table-column prop="stu_class" label="班级">
            </el-table-column>
            <el-table-column prop="chinese" label="语文">
            </el-table-column>
            <el-table-column prop="mathematics" label="数学">
            </el-table-column>
            <el-table-column prop="english" label="英语">
            </el-table-column>
            <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                    <!-- <el-button @click="handleClick(scope.row.id)" type="text" size="small">查看</el-button> -->
                    <el-button type="text" size="small" @click="editStudent(scope.row.stu_id)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="详细信息" :visible.sync="dialogTableVisible" width="50%">
            <el-table :data="subject" border height="400">
                <el-table-column label="学科">
                    <template slot-scope="scope">
                        {{ subject[scope.$index] }}
                    </template>
                </el-table-column>+
                <el-table-column label="成绩">
                    <template slot-scope="scope">
                        {{ RealData[student_index][subject[scope.$index]] }}
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import { studentAPI } from '@/api';
import axios from 'axios';
export default {
    name: 'VirtualData',

    data() {
        return {
            tableData: [],
            dialogTableVisible:false
        }
    },
    methods:{
        editStudent(id){
            this.dialogTableVisible = true
        }
    },  
    mounted() {
        axios({
            url: studentAPI+'/list',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
            },
            methods: "GET",
        }).then((
            err => {
                this.$message({
                    message: err.response.data,
                    type: 'warning'
                });
            },
            res => {
                this.tableData = res.data.data
            }
        ))
    },
};
</script>